import {Box, Button, Card} from "@mui/material";
import React from "react";
import useCadastroLaudoMorte from "./useCadastroLaudoMorte";
import useValidation from "../../../main-class/form/validation/useValidation";
import TextAreaFormField from "../../../main-class/form/input/TextAreaFormField";
import {FileDownload} from "@mui/icons-material";
import {DiagnosticoDTO} from "../../../dao";
import MultipleArquivosFormField from "../../../main-class/form/input/MultipleArquivosFormField";
import ConsultaTratamentosAnimal from "../componentes/ConsultaTratamentosAnimal";
import ConsultaMovimentacoesAnimal from "../componentes/ConsultaMovimentacoesAnimal";
import SelectRadioFormField from "../../../main-class/form/input/SelectRadioFormField";
import SelectItem from "../../../main-class/form/input/SelectItem";
import TextFormField from "../../../main-class/form/input/TextFormField";
import SelectLocalMorte from "../componentes/SelectLocalMorte";

export interface DiagnosticoArray {
    id: string,
    diagnostico: DiagnosticoDTO
}

export default function CadastroLaudoMorte() {
    const {
        formControl,
        submitForm,
        entity,
        cadastroHabilitado,
        salva,
        downloadDocumentoAssinado,
        salvaLocalMorte
    } = useCadastroLaudoMorte();
    const {validate, required, length} = useValidation();

    return (
        <>
            <form id="laudoForm" onSubmit={submitForm}>
                <Card>
                    <h2>Diagnóstico</h2>

                    <Box sx={{display: 'flex', gap: '20px'}}>
                        <Box sx={{flex: 1}}>
                            <TextFormField name="diagnostico.tipo.nome" control={formControl} label="Tipo de óbito"
                                           value={entity.diagnostico?.tipo?.nome} disabled={true}/>
                        </Box>

                        <Box sx={{flex: 1}}>
                            <Box sx={{display: 'flex', gap: '20px'}}>
                                <SelectLocalMorte name="diagnostico.local"
                                                  control={formControl}
                                                  label="Local do óbito"
                                                  disabled={!cadastroHabilitado}
                                                  value={entity.diagnostico?.local}
                                                  validate={validate(
                                                      required(),
                                                  )}/>
                                <Box sx={{margin: '0px 0 45px'}}>
                                    <Button  onClick={() => salvaLocalMorte()}>
                                        Adicionar local
                                    </Button>
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                </Card>

                <Card>
                    <TextAreaFormField name="diagnostico.observacao" label="Observação"
                                       disabled={!cadastroHabilitado} rows={10}
                                       validate={validate(
                                           length({max: 280}),
                                       )}
                                       value={entity.diagnostico?.observacao} control={formControl}/>
                    <Box sx={{display: 'flex', gap: '20px'}}>
                        <SelectRadioFormField name="diagnostico.escoreCondicaoCorporal"
                                              value={entity.diagnostico?.escoreCondicaoCorporal}
                                              control={formControl}
                                              disabled={!cadastroHabilitado}
                                              validate={validate(
                                                  required(),
                                              )}
                                              label="Condição Escore Corporal">
                            <SelectItem value={DiagnosticoDTO.EscoreCondicaoCorporalEnum.Debilitado}
                                        label={"Debilitado"}/>
                            <SelectItem value={DiagnosticoDTO.EscoreCondicaoCorporalEnum.Magro} label={"Magro"}/>
                            <SelectItem value={DiagnosticoDTO.EscoreCondicaoCorporalEnum.Gordo} label={"Gordo"}/>
                        </SelectRadioFormField>
                    </Box>
                </Card>

                <Card>
                    <Box sx={{display: 'flex', gap: '20px'}}>
                        <Box sx={{flex: 1}}>
                            <MultipleArquivosFormField label="Animal" name="diagnostico.midiasAnimal"
                                                       control={formControl} value={entity.diagnostico?.midiasAnimal}
                                                       accept={[".png", ".jpeg", ".jpg"]}
                                                       disabled={!cadastroHabilitado}
                                                       validate={
                                                           validate(
                                                               required()
                                                           )
                                                       }/>
                        </Box>
                        <Box sx={{flex: 1}}>
                            <MultipleArquivosFormField label="Identificacao" name="diagnostico.midiasIdentificacao"
                                                       control={formControl}
                                                       value={entity.diagnostico?.midiasIdentificacao}
                                                       accept={[".png", ".jpeg", ".jpg"]}
                                                       disabled={!cadastroHabilitado}
                                                       validate={
                                                           validate(
                                                               required()
                                                           )
                                                       }/>
                        </Box>
                    </Box>
                    <Box sx={{display: 'flex', gap: '20px'}}>
                        <Box sx={{flex: 1}}>
                            <MultipleArquivosFormField label="Marca" name="diagnostico.midiasMarca"
                                                       control={formControl} value={entity.diagnostico?.midiasMarca}
                                                       accept={[".png", ".jpeg", ".jpg"]}
                                                       disabled={!cadastroHabilitado}/>
                        </Box>
                        <Box sx={{flex: 1}}>
                            <MultipleArquivosFormField label="Necropsia" name="diagnostico.midiasNecropsia"
                                                       control={formControl} value={entity.diagnostico?.midiasNecropsia}
                                                       accept={[".png", ".jpeg", ".jpg"]}
                                                       disabled={!cadastroHabilitado}/>
                        </Box>
                    </Box>
                </Card>

                <ConsultaMovimentacoesAnimal/>

                <ConsultaTratamentosAnimal/>

                <Card>


                    <div className="acoes">
                        {
                            !cadastroHabilitado &&
                            <Button onClick={downloadDocumentoAssinado} variant="outlined" color="primary">
                                <FileDownload/>
                                Baixar Laudo de óbito
                            </Button>
                        }
                        {
                            cadastroHabilitado &&
                            <>
                                <Button onClick={salva} variant="contained" form="laudoForm" id="salvar">
                                    Salvar
                                </Button>
                                <Button type="submit" variant="contained" color="secondary" form="laudoForm"
                                        id="finalizar">
                                    Finalizar Laudo de óbito
                                </Button>
                            </>
                        }

                    </div>
                </Card>
            </form>

        </>
    );
}


